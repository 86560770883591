<template>
  <v-layout v-if="isDeliveryHoursDisabled" justify-center align-center column>
    <v-flex xs4 class="mt-4">
      <v-img :src="require('../settings/delivery-placeholder.svg')" width="150"></v-img>
    </v-flex>
    <v-flex shrink>
      <span class="Body-1-Black-High-Emphasis-Center">{{ deliveryDisabledMessage }}</span>
    </v-flex>
  </v-layout>

  <v-layout v-else flex column>
    <v-layout v-if="loading" justify-center>
      <div class="mt-5">
        <v-container grid-list-xl>
          <v-layout column justify-center fill-height align-center style="height: 45vh;">
            <v-progress-circular
              :size="90"
              :width="9"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-layout>
        </v-container>
      </div>
    </v-layout>

    <v-form v-else xs12 ref="form" lazy-validation v-model="isFormValid">
      <v-container text-xs-center v-if="enabled">
        <v-layout mt-4 row wrap>
          <v-flex xs12>
            <template v-for="day in deliveryHours">
              <hours :key="day.id" :day.sync="day"></hours>
            </template>
          </v-flex>
        </v-layout>
      </v-container>
      <v-layout v-else align-center justify-center row wrap>
        <v-flex xs12>
          <v-img
            :src="require('@/assets/menu-no-operation-hours.svg')"
            :scale="1"
            :contain="true"
            class="mt-5 mb-3"
            height="350px"
          />
        </v-flex>
        <v-flex xs12>
          <div class="Body-1-Black-High-Emphasis-Center">
            You must enable Delivery in brand configurations to set hours
          </div>
        </v-flex>
      </v-layout>
    </v-form>
    <v-flex xs12 style="position: sticky; bottom: 0;" v-if="isFormDirty">
      <v-btn
        class="mt-4"
        color="primary rounded m-2"
        @click="saveCalendar"
        :loading="saving"
        style="float: right;"
      >
        <v-icon class="mr-3">mdi-content-save</v-icon>Save
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import DateTime from 'luxon/src/datetime.js';
import { mapState, mapGetters, mapActions } from 'vuex';
import { CalendarTypes } from '@/constants';
import * as BrandHoursHelpers from '@/views/sites/helpers';
import hours from '../../hours';

export default {
  name: 'DeliveryHours',
  components: {
    hours,
  },
  data: () => ({
    deliveryHours: [],
    deliveryHoursToCompare: [],
    isFormValid: false,
    loading: true,
    saving: false,
  }),

  async beforeRouteLeave(to, from, next) {
    if (this.isFormDirty) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  watch: {
    isActiveBrandCalendarFetched: {
      async handler(value) {
        this.loading = true;
        if (!value) return;
        this.initDeliveryHours();
        this.loading = false;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('calendar', ['isActiveBrandCalendarFetched']),
    ...mapState('sites', ['active_brand', 'active_site']),
    ...mapGetters('calendar', {
      getEvents: 'getEvents',
    }),
    today() {
      return DateTime.local().toISODate();
    },
    isDeliveryHoursDisabled() {
      const deliverySupported = Boolean(this.active_brand?.is?.delivery_supported);
      const isCentricOSStation = Boolean(this.active_brand?.is?.cafe360);
      return !deliverySupported || isCentricOSStation;
    },
    deliveryDisabledMessage() {
      const isCentricOSStation = Boolean(this.active_brand?.is?.cafe360);
      return isCentricOSStation
        ? 'Delivery hours automatically generated for CentricOS enabled station.'
        : 'Delivery is not enabled for this station.';
    },
    isFormDirty() {
      return !isEqual(this.deliveryHoursToCompare, this.deliveryHours);
    },
    enabled() {
      return this.active_brand && this.active_brand.is && this.active_brand.is.delivery_supported;
    },
  },
  methods: {
    ...BrandHoursHelpers,
    initDeliveryHours() {
      const deliveryHoursEvents = this.getEvents(CalendarTypes.delivery);
      this.deliveryHours = this.convertEventsToHours(deliveryHoursEvents);

      for (let index = 0; index < 7; index += 1) {
        const day = this.deliveryHours.find((hour) => hour.id === index);

        if (!day) {
          this.deliveryHours.push({
            id: index,
            dayLabel: this.dayOfWeekAsInteger(index),
            openTime: 'Closed',
            closeTime: 'Closed',
            is24Hours: false,
            isClosedAllDay: true,
            duration: 0,
            isCloseNextDay: false,
          });
        } else {
          day.duration = this.getDuration(day);
        }
      }
      this.deliveryHours.sort((a, b) => (a.id < b.id ? -1 : 1));
      this.deliveryHoursToCompare = cloneDeep(this.deliveryHours);
    },
    getDuration(times) {
      if (!times.openTime || !times.closeTime || times.isClosedAllDay) return 0;
      const today = DateTime.local();
      const startTimes = times.openTime.split(':');
      const closeTime = times.closeTime.split(':');

      let startDateTime = DateTime.local();
      let closeDateTime = DateTime.local();

      if (startTimes.length === 1) {
        startDateTime = today.set({ hour: startTimes[0], minutes: '0' });
      } else {
        startDateTime = today.set({ hour: startTimes[0], minute: startTimes[1] });
      }

      if (closeTime.length === 1) {
        closeDateTime = today.set({ hour: closeTime[0], minutes: '0' });
      } else {
        closeDateTime = today.set({ hour: closeTime[0], minute: closeTime[1] });
      }
      let duration = closeDateTime.diff(startDateTime, 'hours');
      let hours_diff = duration.values.hours;

      if (hours_diff <= 0) {
        times.isCloseNextDay = true;
        const nextDayCloseDateTime = closeDateTime.plus({ days: 1 });
        duration = nextDayCloseDateTime.diff(startDateTime, 'hours');
        hours_diff = duration.values.hours;
      } else {
        times.isCloseNextDay = false;
      }

      return hours_diff;
    },
    convertEventsToHours(eventHours) {
      return eventHours.map((event) => {
        const { start, end, isAllDay, isCloseAllDAy } = this.getStartAndEndTimes(event.schedule);

        return {
          id: event.schedule.dayOfWeek[0],
          dayLabel: this.dayOfWeekAsInteger(event.schedule.dayOfWeek[0]),
          name: event.data.title,
          openTime: start,
          closeTime: end,
          is24Hours: isAllDay,
          isClosedAllDay: isCloseAllDAy,
          duration: 0,
          isCloseNextDay: false,
        };
      });
    },
    convertHoursToEvents(deliveryHours) {
      return deliveryHours
        .filter((deliveryHour) => !deliveryHour.isClosedAllDay)
        .map((deliveryHour) => {
          const schedule = { dayOfWeek: [deliveryHour.id] };

          if (deliveryHour.is24Hours) {
            schedule.duration = 1;
          } else {
            schedule.duration = Math.abs(deliveryHour.duration);
            schedule.times = [deliveryHour.openTime];
          }
          return {
            schedule,
            data: {
              title: 'Delivery Hours',
              color: '#ff8c84',
              calendar: CalendarTypes.delivery,
            },
          };
        });
    },
    async saveCalendar() {
      const v = this.$refs.form.validate();
      if (!this.isFormValid || !v) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }
      const deliveryHoursEvents = this.convertHoursToEvents(this.deliveryHours);
      try {
        this.saving = true;
        await this.saveEvents({ events: deliveryHoursEvents, type: CalendarTypes.delivery });
        this.$store.dispatch('sites/fetchSite', {
          id: this.active_site.id,
          multigroupId: this.$route.params.app,
          nocache: 1,
        });
      } catch (error) {
        this.$toast.error('Error saving Delivery hours');
        return;
      }
      this.saving = false;

      this.$toast('Delivery hours saved');
      this.deliveryHoursToCompare = cloneDeep(this.deliveryHours);
    },
    ...mapActions('calendar', { saveEvents: 'saveEvents' }),
  },
};
</script>

<style></style>
